<template>
  <b-row>
    <b-col md="6" lg="6">
      <b-card>
        <b-img
          fluid
          class="p-2"
          :src="require('@/assets/images/icons/ic-hacer-pedido.png')"
          style="max-height: 200px; display: block;margin-left: auto;margin-right: auto;"
        />
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          style="display: block;margin-left: auto;margin-right: auto;margin-bottom: 10px;margin-top: 10px;"
          @click="goto('products')"
        >
          {{ $i18n.messages.OrderBtn }}
        </b-button>
      </b-card>
    </b-col>
    <b-col md="6" lg="6">
      <b-card>
        <b-img
          fluid
          class="p-2"
          :src="require('@/assets/images/icons/ic-disponible.png')"
          style="max-height: 200px; display: block;margin-left: auto;margin-right: auto;"
        />
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          style="display: block;margin-left: auto;margin-right: auto;margin-bottom: 10px;margin-top: 10px;"
          @click="goto('products', 'disponible')"
        >
          {{ $i18n.messages.AvailableBtn }}
        </b-button>
      </b-card>
    </b-col>
    <b-col md="6" lg="6">
      <b-card>
        <b-img
          fluid
          class="p-2"
          :src="require('@/assets/images/icons/ic-todos.png')"
          style="max-height: 200px; display: block;margin-left: auto;margin-right: auto;"
        />
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          style="display: block;margin-left: auto;margin-right: auto;margin-bottom: 10px;margin-top: 10px;"
          @click="goto('orders')"
        >
          {{ $i18n.messages.AllOrdersBtn }}
        </b-button>
      </b-card>
    </b-col>
    <b-col md="6" lg="6" v-if="user.rol_id === 0">
      <b-card>
        <b-img
          fluid
          class="p-2"
          :src="require('@/assets/images/icons/ic-todos.png')"
          style="max-height: 200px; display: block;margin-left: auto;margin-right: auto;"
        />
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          style="display: block;margin-left: auto;margin-right: auto;margin-bottom: 10px;margin-top: 10px;"
          @click="goto('reports')"
        >
          {{ $i18n.messages.ReportBtn }}
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
  created() {
    console.log(this.user);
    if (this.user.rol_id === 3) {
      this.getCustomerClient();
    }
  },
  methods: {
    ...mapActions({
      getCustomerLogged: "customers/getCustomerLogged",
    }),
    goto(path, option) {
      if (option === 'disponible') {
        localStorage.setItem("verPedido", JSON.stringify('d-none'));
      } else {
        localStorage.setItem("verPedido", JSON.stringify(''));
      }
      this.$router.push({ name: path });
    },
    async getCustomerClient() {
      const currentCustomer = JSON.parse(
        localStorage.getItem("currentCustomer")
      );
      if (!currentCustomer) {
        console.log("NO TIENE CUSTOMER");
        const currentCustomer = await this.getCustomerLogged(this.user.usuario);
        console.log("CUS: ", currentCustomer);
      } else {
        console.log("TIENE CUSTOMER");
        console.log(currentCustomer);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  ...mapMutations({
    setLoading: "app/setLoading",
  }),
};
</script>
